nav {	 
	padding-top: 1rem;
	padding-bottom: 1rem;
}

nav > ul {
	list-style-type: none;	
	text-align: center;
	background-color: var(--bg_secundary);
	margin-left: 3rem;
	margin-right: 3rem;
	display: flex;
	justify-content: space-between;	
	
}

nav > ul > li {
	color: var(--nav_primary);	
	border-right: 2px solid var(--tertiary);
	border-bottom: 3px solid var(--tertiary);
	font-size: 1rem;
	padding-top: 0.9rem;
	padding-left: 0.9rem;
	padding-right: 0.9rem;
	padding-bottom: 0.9rem;
	width: 100%;
	width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

}

nav > ul > li:hover {
	cursor: pointer;
	border-bottom: 3px solid var(--nav_secundary);
}

.active-item {
	color: var(--nav_primary);
	font-weight: bolder;
}

.menu-mobile {
	display: none;
}

.menu-mobile button {
	background-color: var(--bg_primary);
	border: 0px;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.menu-mobile {
		display: flex;
		justify-content: space-between;
		width: 100%;

		border-bottom: 1px var(--nav_secundary) solid;
	}

	.menu-mobile-title {
		color: var(--primary);
		display: inline-block;
		font-weight: bolder;
		margin: auto;
	}

	.menu-mobile-icon {
		border: 1px rgb(30, 30, 30) solid;
		padding: 10px;
		display: inline-block;
	}

	nav {
		position: relative;
		display: inline-block;
		padding: 0px;
		width: 100%;
	}



	.dropdown-child-open {
		display: block;
	}

	.dropdown-child {
		transition: all 1s ease;
		display: flex;
		position: absolute;
		background-color: var(--bg_primary);
		width: 100%;
		left: -100%;
		z-index: 1;
		 
		height: 250px;
		flex-direction: column;
		list-style-type: none;
		grid-gap: 0px;
		opacity: 0;
	}

	.dropdown-child li {
		text-decoration: none;
		display: block;
		border-bottom: 1px var(--bg_primary) solid;
	}

	.dropdown-child li.active-item {
		border-left: 1px var(--nav_primary) solid;
		border-right: 1px var(--nav_primary) solid;
	}

	.dropdown-child.active {
		background-color: var(--bg_secundary);
		border-bottom: 1px var(--primary) solid;
		left: 0;
		opacity: 1;
		z-index: 1;
		transition: all 0.5s ease;
		z-index: 1;
		align-content: center;
		padding-left: 0px;
	}

	nav > ul {
		padding-left: 0rem;
		padding-right: 0rem;
		margin-left: 0rem;
	}
}

/* Smartphones (landscape) ----------- */

@media only screen and (min-width: 321px) {
}

/* Smartphones (portrait) ----------- */

@media only screen and (max-width: 320px) {
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* iPads (landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	/* Styles */
}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* Desktops and laptops ----------- */

@media only screen and (min-width: 1224px) {

	nav > ul {	 
		margin-left: 12rem;
		margin-right: 12rem;
	}
}

/* Large screens ----------- */

@media only screen and (min-width: 1824px) {
}

/* iPhone 4 ----------- */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
}
