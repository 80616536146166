.albumlist {
	display: flex;
	flex-wrap: wrap;
  padding-right: 1rem;
  padding-top: 1rem;
}

.albumitem {
	margin: 5px;
	height: 40vh;
	flex-grow: 1;
}

.albumimg {
	max-height: 100%;
	min-width: 100%;
	object-fit: cover;
	vertical-align: bottom;
}
/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .albumitem {
        margin: 5px;
        height: 100%;
        flex-grow: 1;
    }
    
    .albumimg {
        max-height: auto;
        min-width: auto;
        object-fit: cover;
        vertical-align: bottom;
        width: 100%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  
  @media only screen and (min-width: 321px) {}
  
  /* Smartphones (portrait) ----------- */
  
  @media only screen and (max-width: 320px) {
   
  }
  
  /* iPads (portrait and landscape) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
   
  }
  /* iPads (landscape) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles */
  }
  
  /* iPads (portrait) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {}
  
  /* Desktops and laptops ----------- */
  
  @media only screen and (min-width: 1224px) {
    
  }
  
  /* Large screens ----------- */
  
  @media only screen and (min-width: 1824px) {}
  
  /* iPhone 4 ----------- */
  
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {}