.links h3 {    
    display: flex;
    color: var(--primary);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.links div {
    background-color: var(--bg_secundary);
    margin-bottom: 1rem;
    border-bottom: 3px solid var(--tertiary);
}

.link-image {
    width: -webkit-fill-available;
}

.link {
    cursor: pointer;    
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .links img {
        width: 250px;
        margin: auto;
        display: block;
    }
}

/* Smartphones (landscape) ----------- */
@media only screen 
and (min-width : 321px) {
    .header {
        background-size: cover;
    }
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 320px) {
    .header {
        background-size: cover;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
    .header {
        background-size: cover;
    }
    .links img {
        width: 150px;
        margin: auto;
        display: block;
    }    
}

/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .header {
        background-size: cover;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
    .header {
        background-size: cover;
    }
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
    .header {
        background-size: cover;
    }
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
    .header {
        background-size: contain;
    }
}