:root {
    --primary: #333333;
    --secundary: #8E9CC6;
    --tertiary: #bdc9d1;
    
    --nav_secundary: #0F69B4;
    --nav_primary: #337ab7;

    --article_primary:#337ab7;
    --article_secundary:#475156;
    --article_tertiary: #7a8791;
    --article_email: #0f69b4;

    --bg_primary: #EFEFEF;
    --bg_secundary: #FFF;    

    --facebook: #0070bb;

    --error: #f00;
    
}