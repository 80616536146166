@import '../../style/variables.css';

footer{
    font-size: .8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: var(--secundary);
}