html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}



@import './style/variables.css';
body {
  background-color: var(--bg_primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  flex: 1;
}

.col-05 {
  flex: 0.5;
}

.col-10 {
  flex: 1;
}

.col-15 {
  flex: 1.5;
}

.col-20 {
  flex: 2;
}

.col-25 {
  flex: 2.5;
}

.col-30 {
  flex: 3;
}

.col-35 {
  flex: 3.5;
}

.col-40 {
  flex: 4;
}

.col-45 {
  flex: 4.5;
}

.col-50 {
  flex: 5;
}

.col-55 {
  flex: 5.5;
}

.col-60 {
  flex: 6;
}

.col-65 {
  flex: 6.5;
}

.col-70 {
  flex: 7;
}

.col-75 {
  flex: 7.5;
}

.col-80 {
  flex: 8;
}

.col-85 {
  flex: 8.5;
}

.col-90 {
  flex: 9;
}

.col-95 {
  flex: 9.5;
}