@import "../../style/variables.css";

.followus h3 {
	margin-top: 1rem;
	display: flex;
	color: var(--primary);
}

.followus div {
	background-color: var(--bg_secundary);
}

.facebook {
	color: var(--primary);
	display: flex;
	padding: .5rem;
	background-color: var(--bg_primary);
	border-bottom: 3px solid var(--tertiary);
	margin-top: 1rem;
}

.facebook .icon {
	color: var(--tertiary);
	width: 2rem; 
	height: 2rem;
}

.facebook .title {
	color: var(--primary);
	font-weight: bolder;
	margin-left: 0.8rem;
	line-height: 2rem;
}

.facebook .name {
	font-style: italic;
	color: var(--tertiary);
	margin-left: 0.8rem;
	line-height: 2rem;
}

.facebook:hover {
	cursor: pointer;
	color: var(--bg_primary);
	background-color: var(--facebook);
}

.facebook:hover .title {
	color: var(--bg_primary)
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.facebook {
		display: flex;
		padding: 1rem;
	}
}

/* Smartphones (landscape) ----------- */

@media only screen and (min-width: 321px) {
}

/* Smartphones (portrait) ----------- */

@media only screen and (max-width: 320px) {
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
/* iPads (landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	/* Styles */
}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* Desktops and laptops ----------- */

@media only screen and (min-width: 1224px) {
}

/* Large screens ----------- */

@media only screen and (min-width: 1824px) {
}

/* iPhone 4 ----------- */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
}
