@import '../../style/variables.css';

.contact {
    background-color: var(--bg_secundary);
    margin-bottom: 1rem;
     
    padding: 2rem;
}

.contact h1 {
    font-family: inherit;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
    color: var(--article_primary);
    margin-left: 2rem;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    color: var(--primary);
}

.form-control {
    display: block;
    width: 100%; 
    color: #555;
    background-color: #fff;
    background-image: none;     
    height: calc(1.5em + .75rem + 2px);   
    font-size: 1rem;
    font-weight: 400;

    background-clip: padding-box;    
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.form-group {
    margin-bottom: 15px;
}

.success {
    color: var(--primary);
    padding-bottom: 1rem;
}

.fail{
    color: var(--error);
    padding-bottom: 1rem;
}