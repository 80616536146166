.donations-container {
	display: flex;
    padding: 1rem;
    background-color: var(--bg_secundary);
}

.logobank {
    width: 10rem;
}

.databank {
    color: var(--primary);
	margin: 1rem;
    line-height: 2rem;
    vertical-align: middle;
}