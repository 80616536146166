@import '../../style/variables.css';

.about {
    background-color: var(--bg_secundary);
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
}

.about h1 {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: var(--article_primary);
    margin-left: 2rem;
    text-align: center;
}

.about h2 {
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.1;
    color: var(--article_primary);
    margin-left: 2rem;
    margin-top: 1rem;
    text-align: center;
}

.about hr {
    margin-left: 2rem;
    margin-right: 2rem;
}

.about p {    
    color: var(--article_tertiary);
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: justify;
    line-height: 2rem;
}

.about img {
    width: 50%;
    display:block;
    margin:auto;
    
}