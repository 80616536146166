.header {
    will-change: opacity;     
    height: 240px;
    width: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
}

 

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .header {
        background-size: cover; 
        display: none;
    }
}

/* Smartphones (landscape) ----------- */
@media only screen 
and (min-width : 321px) {
    .header {
        background-size: cover;
    }
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 320px) {
    .header {
        background-size: cover;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
    .header {
        background-size: contain;
        height: 150px;
        margin-top: 0px;
        padding-top: 0px;
    }
}

/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .header {
        background-size: cover;
    }
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
    .header {
        background-size: cover;
    }
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
    .header {
        background-size: cover;
    }
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
    .header {
        background-size: contain;
    }
}